<template>

        <div class="" :class="dynamicClass" uk-modal ref="modal">
            <div class="uk-modal-dialog"
            :class="{'uk-margin-auto-vertical' : verticalCenter}">
                <button v-if="closeButton"
                        class="uk-modal-close-default"
                        type="button"
                        @click="$emit('modal:closed')"
                        uk-close></button>
                <div class="uk-modal-header" v-if="title">
                    <slot name="title" :modal="this">
                        <h2 class="uk-modal-title" >{{titleText}}</h2>
                    </slot>

                </div>

                <div class="uk-modal-body">
                    <slot default :modal="this">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </slot>
                 </div>

                <div class="uk-modal-footer" v-if="showFooter">
                    <slot name="footer" :modal="this">
                        <div class="footer-inner">
                            <button class="uk-button uk-button-primary"
                                    type="button"
                                    @click="this.okClickHandler">{{translate('core.ui.modal.okButtonText')}}</button>
                        </div>
                    </slot>
                 </div>
            </div>
        </div>

</template>

<script>

    import {computed} from 'vue'
    export default {
        props: {
            /**
             * Is the modal showing - uses watch internally to implement
             **/
            show : {
                type: Boolean,
                default: false,
            },
            /**
             * Title for the modal.
             * False - no modal header
             * True - show default :title attribute text
             * True + content to #title slot - show custom slot html
             */
            title: {
                type: [Boolean, String],
                default: false,
            },
            type: {
              type: String,
              default: 'dialog',
            },
            /**
             * Show Little X close button on top?
             */
            closeButton: {
                type: Boolean,
                default: true
            },
            /**
             * Show footer? default is true. There is also a slot for the footer content
             */
            showFooter: {
                type: Boolean,
                default: true,
            },
            verticalCenter: {
                type: Boolean,
                default: false
            }
        },
         data: function () {
            return {
                localShow : this.show,
            };
        },
        computed : {
            titleText() {
                if (typeof this.title === 'string') {
                    return this.title;
                }

                return this.translate('core.ui.modal.promptTitle');
            },
            dynamicClass () {
                let result = [];

                if (this.type == 'full') {
                    result.push ('uk-modal-full');
                }

                return result;
            }
        },
        emits: [
          'modal:confirmed', 'modal:closed'
        ],
        methods: {
            closeModal () {
                utilities.waitUntil(()=> {return typeof this.$s == 'object'}, 10).then( (result) => {
                    this.$s.ui.modal(this.$refs.modal).hide();
                });
            },
            openModal () {
                utilities.waitUntil(()=> {return typeof this.$s == 'object'}, 10).then( (result) => {
                    this.$s.ui.modal(this.$refs.modal).show();
                });

            },
            showModal () {
                return this.openModal();
            },
            hideModal () {
                return this.closeModal();
            },
            okClickHandler() {

                this.localShow = false;
                this.hideModal();
                this.$emit('modal:closed'); // this allows parent to fallow state, and update the show prop if they want
                this.$emit('modal:confirmed');
            }
        },
        created () {

        },
        watch: {
            show: {
                immediate: true,
                handler (newVal) {
                    if (utilities.isSSR()) {
                        return;
                    }

                    if (newVal) {
                        this.localShow = newVal;
                        this.showModal();
                    } else {
                        this.localShow = newVal;
                        this.hideModal();
                    }

                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .uk-modal-footer {
        text-align: end;
    }
</style>
