<template>

    <div class="wrap" style="position: relative;">

        <date-picker v-model="value" :input-debounce="200"
        :modelConfig="{
            type: 'string',
            mask: 'YYYY-MM-DD', // Uses 'iso' if missing
          }">

            <template v-slot="{ inputValue, inputEvents }">
                <input
                        class=" uk-input bg-white border px-2 py-1 rounded"
                        :placeholder="placeholder"
                        :value="inputValue"
                        v-on="inputEvents"
                />
            </template>
        </date-picker>

<!--
        <Calendar class="calendar"
                  style="position: absolute; top: calc(100%+8px);"
                  v-model="value"
                  :model-config="{
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      }"
                  mode="date"
                   />-->
     <!--   <input v-model="value"
               class="uk-input"
               v-bind="inputAttrs"
               :type="compoutedInputType"
               @focus="isFocused = true" @blur="isFocused = false"/>-->
    </div>



</template>

<script>
import { Calendar, DatePicker } from 'v-calendar';
    export default {
        props: {
            modelValue    : {},
            placeholder: {},
            class: {},
            type: {},
            list: {} // prevent inheritence of list="" on element
        },
        data: function () {
            return {
                date: new Date(),
                isFocused: false,
                test: null
            };
        },
        components: {DatePicker, Calendar},
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            inputAttrs() {
                let attrs = {...this.$attrs};
                delete attrs.list;
                return attrs;
            },
            compoutedInputType () {
                return this.isFocused ? 'date' : 'text'
            }
        },
        methods: {

        }

    }
</script>

<style scoped lang="scss">

</style>
